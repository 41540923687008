@import url('https://fonts.googleapis.com/css2?family=Syne+Tactile&display=swap');

.MainPageContainer {
    max-width: 900px;
    margin: 1em auto 2em auto;
    padding: 0;
    background-color: white;
    border-radius: 8px
}

@media (min-width: 800px) {
    .MainPageContainer {
        box-shadow: 0 0 1em #00000018;
        padding: 2%;
    }
}

@media (max-width: 800px) {
    main {
        margin: 0;
    }
}

.MainPageHeading {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.BookpointLogo {
    width: min(150px, 22vw);
    margin-left: 5px;
    margin-bottom: -3px;
}

.MainPageHeading h1 {
    font-size: min(55px, 8vw);
    margin: 10px;
}

.Shelf {
   height: min(2vw, 10px);
   border-radius: 2px;
   background-color: #96613d;
   border-top: 4px solid #83502e;
}

@media (max-width: 800px) { .Shelf { border-radius: 0; } }
@media(max-width:500px) { .Shelf { height: 2vw; } }


@media (min-width: 800px) {
    .MainPageBody {
        margin: 0 2%;
    }
}

.BookShelfTop {
    margin-top: min(2vw, 2em);
    margin-left: 1vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
}

.PlantPot {
    max-width: 16%;
    margin-bottom: calc(16% * -0.937);
    margin-right: 2%;
    z-index: 100;
}

@media (max-width: 900px) {
    .PlantPot {
        display: none;
    }
}

.MainPageSubjects {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-end;
    margin-top: 2em;
    margin-left: 1em;
}

@media (max-width: 600px) {
    .MainPageSubjects {
        margin-left: -1em;
    }
    .BookShelfTop {
        justify-content: center;
    }
}

.SubjectBook {
    --book-width: min(10vw, 40px);
    --book-height: min(24vw, 110px);
    --book-right: min(8vw, 35px);

    /* height: var(--book-height); */
    width: calc(var(--book-width) + var(--book-right));
    border-right: var(--book-right) solid var(--subject-clr1);
    border-radius: 2px;
    margin-right: calc(var(--book-right) * -0.65);

    background-color: var(--subject-clr0);
    color: var(--clr-dark);

    font-family: var(--ff-secondary);
    writing-mode: vertical-rl;
    text-decoration: none;
    text-align: center;
    font-size: calc(var(--book-width) * 0.68);
    z-index:0;
    transition: transform 0.35s ease; /* vendorless fallback */
    padding: 1.5em 0em;
}

.SubjectBook:hover {
    opacity: 1;
    transform: translateY(calc(var(--book-height) * -0.2));
}

/* This book is on its left side */
.LastBook {
    --border-width: min(0.8vw, 2px);

    width: calc(var(--book-height) + var(--book-right));
    height: var(--book-width);
    border-right: var(--book-right) solid var(--subject-clr0);
    border-top: var(--border-width) solid var(--subject-clr1);
    border-bottom: var(--border-width) solid var(--subject-clr0);
    writing-mode: unset;
    padding: 0;
}

.LastBook::after {
    content: "";
    background-color: bisque;
    position: absolute;
    height: calc(var(--book-width) - 2 * var(--border-width));
    width: calc(var(--book-right) + 1px);
    border-radius: 3px 0 0 3px;
    margin-left: calc(0.5 * var(--book-height) - 0.5 * var(--book-right) - 8px);
}

.OpeningHours {
    display: flex;
    justify-content: space-around;
    margin: 20px;
    font-family: var(--ff-secondary);
}

@media (max-width: 580px) {
    .OpeningHours .oh {
        display: none
    }
}

.splide__slide img {
    height: 100%;
    object-fit: contain;
}


.MainPageBody p {
    text-align: center;
    padding: min(4.0vw, 1rem);
}


@media (max-width: 800px) {
    .App {
        background: white;
    }
}
