.PageHeading {
    text-align: center;
    margin: min(2vw, .3em) 0 min(3vw, .4em) 0;
}

/* Missing books page */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.NotFound {
    animation: 1s ease-in 0s 1 fadeIn;
    text-align: center;
    padding: 1em;
}
/* Missing books page */

.SearchForm {
    padding: 1em;
    text-align: center;
}

.SearchForm > input[type="text"] {
    padding: 0.4rem;
    width: 250px;
    font-size: 1em;
    font-family: var(--ff-primary);

    padding-left: 40px;
    background-color: #dee1e6;
    border-radius: 5px;
    background-image: url("../public/search.png");
    background-position: 0.5em;
    background-size: 1em;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 2px;
    border-color: #dee1e6;
    color: #2e3440;
}

.ListItem {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 0.7em 0 0.7em 0;
    padding: 0.5em;
    border-color: #dee1e6;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    color: #202020;
    text-decoration: none;
    background-color: #e7eaf040;
    /* box-shadow: 0 0 0.3em #00000050; */

    /* centering */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    transition: .3s ease;

    border-color: var(--subject-clr0);
    border-width: 0 0 0 10px;
}

.ListItem:hover {
    opacity: 1;
    background-color: #e7eaf0a1;
    transform: scale(1.03);
}

.ErrorListItem {
    background-color: #ff202080;
    text-align: center;
    display: block;
    color: #800000;
    font-size: 2rem;
    font-weight: bolder;
    padding: 1rem;
    font-family: monospace;
}

.ErrorListItem:hover {
    background-color: #ff2020a0;
}

.BookListItemHeading {
    background-color: var(--subject-clr0);
    padding: 0.3em;
    border-radius: 5px;
    margin-top: 0;
}

.BookListItemImage {
    width: 7em;
    object-fit: contain;
}

.BookListItemBlock {
    margin: 0.5em;
    margin-top: 0.2em;
    padding: 0.5em;
    margin-left: 1em;
    flex: 1;
}

.ListItemTitle {
    font-style: normal;
    font-size: 1.2em;
    margin-bottom: 0.5em;
}

.BookListItemInfo {
    font-style: italic;
}

@media (max-width: 800px) {
    .BookListItemDescription {
        display: none;
    }
    .ListItem {
        border-radius: 0;
    }
}

.PageNumbers {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 1.2em;
}

.CurrentPageNumber {
    text-decoration: none;
    font-weight: var(--fw-bold);
    padding: 0.5em;
    margin: 0 0.1em 0 0.1em;
    background-color: #c7c7c78b;
    border-radius: 5px;
    cursor: not-allowed;
}

.PageNumber {
    text-decoration: none;
    font-weight: var(--fw-bold);
    padding: 0.5em;
    margin: 0 0.1em 0 0.1em;
    background-color: #c2c2c250;
    border-radius: 5px;
    cursor: pointer;
}

.AddBookForm {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: .75em;
    background-color: white;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.LoadingIcon {
    max-width: 100px;
    max-height: 100px;
    background-image: url(https://www.gympos.sk/img/design/logo.png);
    background-position: center;
    background-size: 45%;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.LoadingCircle {
    animation: rotation 1s infinite linear;
}

.LoadingText {
    font-size: 1.5rem;
    text-align: center;
}

.SearchControls {
    margin-top: 1em;
    height: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px 0 12px
}

