.BookPageContainer {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: .75em;
    background-color: white;
    box-shadow: 0 0 1em #00000018;
    border-radius: 8px
}

.BookPageContainer h1,
.BookPageContainer h2 {
    padding: 1rem; margin: 0;
}

.BookPageContainer h1 {
    border-radius: 8px 8px 0 0;
    background-color: var(--subject-clr0);
    
    /*
    background-image: radial-gradient(white 1px, transparent 0);
    background-size: 20px 20px;
    background-position: -10px -10px;
    */
}

.BookPageContainer h2 {
    background-color: var(--subject-clr1);
    border-radius: 0 0 8px 8px;
    margin-bottom: .3em;
}

.BookPageContainer h2 a {
    text-decoration: none;
    color: #3a506b;
}

.BookPageContainer h2 a:hover {
    text-decoration: underline;
}

.BookPageText {
    margin: 1.5em 1em;
}

.BookPageImage {
    display: block;
    padding: 1em;
    background-color: #dee1e650;
    border-radius: 15px;
    /* border: 2px solid lightgray; */
    float: right;
    width: 40%;
    margin-left: 1rem ;
}

@media (max-width: 2000) {
    .BookPageImage {
    }
}
