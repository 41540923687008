@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*, *::before, *::after { box-sizing: border-box; }


/* Custom properties */

:root {
    --ff-primary: 'Ubuntu', sans-serif;
    --ff-secondary: 'Bree Serif', serif;
    --clr-dark: #202020;
    --clr-light: #fff;
    --fw-reg: 200;
    --fw-mid: 400;
    --fw-bold: 600;
    --fs-h1: 2.3rem;
    --fs-h2: 1.8rem;
    --fs-h3: 1.2rem;
    --fs-body: 1rem;
}

@media (min-width: 800px) {
    :root {
        --fs-h1: 3rem;
        --fs-h2: 2.3rem;
        --fs-h3: 1.3rem;
        --fs-body: 1.125rem;
    }
}


/* General styles */

body {
    font-family: var(--ff-primary);
    font-size: var(--fs-body);
    font-weight: var(--fw-mid);
    line-height: 1.3;
    color: var(--clr-dark);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

a {
    color: #81a1c9;
    font-weight: var(--fw-mid);
    transition: opacity 0.5s ease;
}

a:hover {
    opacity: 0.8;
}

b, strong { font-weight: var(--fw-bold); }

h1, h2, h3, h4 {
    line-height: 1;
    margin: 0.7em 0;
    font-family: var(--ff-secondary);
    font-weight: var(--fw-mid);
}

h1 { font-size: var(--fs-h1); }
h2 { font-size: var(--fs-h2); }
h3 { font-size: var(--fs-h3); }

hr { 
    width: 93%;
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
    margin-top: 40px;
    margin-bottom: 40px
}

/* Main styling */

.NavBar {
    background-color: #212121;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: var(--ff-secondary);
}

.NavBarButton {
    padding: 1em 0.5em 1em 0.5em;
    text-decoration: none;
}

.NavBarButton:hover {
    background-color: #333333;
}

.App {
    text-align: left;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: radial-gradient(#00000020 2px, transparent 0);
    background-size: 20px 20px;
    background-position: -10px -10px;
}

main {
    flex-grow: 1;
    margin: min(2.5%, 2em);
}

.InfoLink {
    list-style: none;
    text-align: center;
    font-size: 1rem;
    display: block;
}

.InfoLinksHeading {
    text-align: center;
    color: white;
}

.InfoLinksContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #202020;
    padding: 2em;
    width: 100vw;
}

.InfoLinksColumn {
    min-width: 20em;
}